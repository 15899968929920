import React from "react";

const UserDetailModal = ({ user, onClose }) => {
  if (!user) return null; // Don't render the modal if there's no user

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 transition-opacity">
      <div className="bg-white p-6 rounded shadow-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">User Details</h2>
        <p><strong>Name:</strong> {user.name}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Type:</strong> {user.flowType}</p>
        <p><strong>Phone No:</strong> {user.phone}</p>
        <p><strong>Room No:</strong> {user.roomNo}</p>
        {/* Add any additional user details here */}
        <button 
          onClick={onClose} 
          className="mt-4 rounded bg-red-500 py-1 px-3 text-white hover:bg-red-600"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default UserDetailModal;
