import InputField from "components/fields/InputField";
import { useState } from "react";
import { _forgotPassword } from "Services/api";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleVendorForgotPassword = async () => {
    // Reset errors and message
    setErrors({});
    setMessage("");

    // Validation
    if (!email) {
      setErrors({ email: "Email is required" });
      return;
    }
    if (!validateEmail(email)) {
      setErrors({ email: "Please enter a valid email" });
      return;
    }

    setLoading(true);

    try {
      const response = await _forgotPassword({
        body: { email },
      });

      if (response.success) {
        setMessage("Password reset email sent successfully!");
      } else {
        setErrors({ email: response.message || "Failed to send email" });
      }
    } catch (error) {
      setErrors({ email: error.message || "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Loader Overlay */}
      {loading && (
        <div className="loader-overlay">
          <span className="loader"></span>
        </div>
      )}

      {/* Forgot Password Section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white mt-5">
          Forgot Password
        </h4>

        {/* Success Message */}
        {message && (
          <div className="text-green-600 text-sm mb-4">{message}</div>
        )}

        <InputField
          variant="auth"
          extra="mb-3"
          label="Enter your registered email"
          placeholder="mail@mail.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && <span className="text-red-600 text-sm">{errors.email}</span>}

        <button
          onClick={handleVendorForgotPassword}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Send mail
        </button>

        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Already registered?
          </span>
          <a
            href="/auth/sign-in"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
}
