import React, { useState } from 'react';
import { _createSubaccount } from '../../../../Services/api'; // Update the import path as needed

const AddSubaccountModal = ({ isOpen, onClose, onSave }) => {
  const botDetails = JSON.parse(sessionStorage.getItem("botData"));
  const botId = botDetails?.unique_id;
  const accessToken = sessionStorage.getItem('VendorToken');
  const [formData, setFormData] = useState({
    bot_id: botId,
    account_name: '',
    account_number: '',
    account_bank: '',
    business_name: '',
    business_email: '',
    business_mobile: '',
    business_contact_mobile: '',
    business_contact: '',
    country_code: '',
    split_type: '',
    split_value: 0
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'split_value' ? Number(value) : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await _createSubaccount({
        body: formData,
        accessToken
      });
      
      onSave(response);
      onClose();
    } catch (err) {
      setError(err.message || 'Failed to create subaccount');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 md:mx-auto">
        <form onSubmit={handleSubmit}>
          {/* Modal Header */}
          <div className="px-6 py-4 border-b flex justify-between items-center">
            <h3 className="text-lg font-semibold">Add Subaccount</h3>
            <button 
              type="button" 
              onClick={onClose} 
              className="text-gray-400 hover:text-gray-600 text-2xl"
            >
              &times;
            </button>
          </div>

          {/* Scrollable Content */}
          <div className="px-6 py-4 max-h-96 overflow-y-auto">
            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
                {error}
              </div>
            )}
            
            <div className="space-y-2">
              <label className="text-black-800 block font-bold">Account Name</label>
              <input
                type="text"
                name="account_name"
                value={formData.account_name}
                onChange={handleChange}
                placeholder="Account Name"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Account Number</label>
              <input
                type="text"
                name="account_number"
                value={formData.account_number}
                onChange={handleChange}
                placeholder="Account Number"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Bank Name</label>
              <input
                type="text"
                name="account_bank"
                value={formData.account_bank}
                onChange={handleChange}
                placeholder="Bank Name"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Business Name</label>
              <input
                type="text"
                name="business_name"
                value={formData.business_name}
                onChange={handleChange}
                placeholder="Business Name"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Business Email</label>
              <input
                type="email"
                name="business_email"
                value={formData.business_email}
                onChange={handleChange}
                placeholder="Business Email"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Business Mobile</label>
              <input
                type="text"
                name="business_mobile"
                value={formData.business_mobile}
                onChange={handleChange}
                placeholder="Business Mobile"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Business Contact Mobile</label>
              <input
                type="text"
                name="business_contact_mobile"
                value={formData.business_contact_mobile}
                onChange={handleChange}
                placeholder="Business Contact Mobile"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Business Contact</label>
              <input
                type="text"
                name="business_contact"
                value={formData.business_contact}
                onChange={handleChange}
                placeholder="Business Contact"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Country Code</label>
              <input
                type="text"
                name="country_code"
                value={formData.country_code}
                onChange={handleChange}
                placeholder="Country Code"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Split Type(Percentage)</label>
              <input
                type="text"
                name="split_type"
                value={formData.split_type}
                onChange={handleChange}
                placeholder="Split Type"
                className="mt-1 w-full rounded border p-2"
                required
              />

              <label className="text-black-800 block font-bold">Split Value</label>
              <input
                type="number"
                name="split_value"
                value={formData.split_value}
                onChange={handleChange}
                placeholder="Split Value"
                className="mt-1 w-full rounded border p-2"
                required
              />
            </div>
          </div>

          {/* Footer with Buttons */}
          <div className="px-6 py-4 border-t flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border rounded hover:bg-gray-100"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-white bg-[#007bff] rounded hover:bg-[#007bff] disabled:bg-blue-300"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSubaccountModal;
