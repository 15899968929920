import { useContext, useEffect, useState } from "react";
import {
  FaGlobe,
  FaRegCopy,
  FaRegUserCircle,
  FaRobot,
  FaUserEdit,
} from "react-icons/fa";
import { _vendorCategories } from "Services/api";
import { _languageUpdate } from "Services/api";
import { _botCreation } from "Services/api";
import { IoIosGlobe, IoMdClose } from "react-icons/io";
import { _getPaymentGateways } from "Services/api";
import KnowledgeUpdateForm from "./components/KnowledgeUpdateForm";
import { _getLanguage } from "Services/api";
import { _update_bot, _getBotDetails } from "Services/api";
import { MdOutlinePayment } from "react-icons/md";
import ApiKnowledgeSource from "./components/ApiKnowledgeSource";
import PromptGeneration from "./components/PromptGeneration";
import { useParams, Navigate } from "react-router-dom";
import AddSubaccountModal from "./components/AddSubaccountModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { _getActiveLanguage } from "Services/api";
import { _vendorUserName } from "Services/api";
import { ProfileContext } from "context/ProfileContext";
import { clearConfigCache } from "prettier";

const Marketplace = () => {
  const [bgColor, setBgColor] = useState("#ccc");
  const profileData = useContext(ProfileContext);
  const [welcomeMessage, setWelcomeMessage] = useState("Hi! How can we help?");
  const [chatHeader, setChatHeader] = useState("Loading..."); // Start with loading state
  const [isHeaderLoading, setIsHeaderLoading] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [organizationType, setOrganizationType] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [categoryList, setCategoryList] = useState();
  const [isBotCreated, setIsBotCreated] = useState(false);
  const [botId, setBotId] = useState();
  const [scriptTag, setScriptTag] = useState("");
  const [urlTag, seturlTag] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const avatarOptions = [
    "https://plist-staging.edully.com/avatar/avatar-1.png",
    "https://plist-staging.edully.com/avatar/avatar-2.png",
    "https://plist-staging.edully.com/avatar/avatar-3.png",
    "https://plist-staging.edully.com/avatar/avatar-4.png",
    "https://plist-staging.edully.com/avatar/avatar-5.png",
    "https://plist-staging.edully.com/avatar/avatar-6.png",
  ];
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState("");
  const [paymentGateways, setPaymentGateways] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(
    (activeLanguages && activeLanguages[0]?.code) || ""
  );
  const [selectedBot, setSelectedBot] = useState(null);
  const [fetchedLanguages, setFetchedLanguages] = useState([]);
  const [activeTab, setActiveTab] = useState("dashboard");
  const [languageError, setLanguageError] = useState("");
  const [avatarError, setAvatarError] = useState("");
  const [organizationTypeError, setOrganizationTypeError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [logoLink, setLogoLink] = useState("");
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [isKnowledgeApi, setIsKnowledgeApi] = useState();
  const [knowledgeSourceProvide, setKnowledgeSourceProvide] = useState();
  const { id: userId } = useParams();
  const [prompts, setPrompts] = useState([]);
  const [flows, setFlows] = useState([]);
  const [selectedPrompts, setSelectedPrompts] = useState([]);
  const [selectedFlows, setSelectedFlows] = useState([]);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setChatHeader(
        profileData?.first_name
          ? `Welcome to ${profileData.first_name}'s Chat`
          : "Welcome to Chat"
      );
      setIsHeaderLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [profileData]);

  useEffect(() => {
    if (!isHeaderLoading) {
      setChatHeader(
        selectedBot?.botname ||
          (profileData?.first_name
            ? `Welcome to ${profileData.first_name}'s Chat`
            : "Welcome to Chat")
      );
    }
  }, [profileData, isHeaderLoading, selectedBot]);

  useEffect(() => {
    if (isBotCreated) {
      seturlTag(
        username
          ? `https://chatbotdev.plistbooking.app/chatbot/?bot=${username}`
          : `https://chatbotdev.plistbooking.app/chatbot/?bot=${botId}`
      );
    }
  }, [isBotCreated, botId]);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    setLogo(file);
  };

  const handleLogoLinkChange = (e) => {
    setLogoLink(e.target.value);
  };

  const handleAudioToggle = (e) => {
    setAudioEnabled(e.target.checked);
  };

  useEffect(() => {
    if (isBotCreated) {
      const token = sessionStorage.getItem("VendorToken");
      const scriptString = `<script src="https://chatbotdev.plistbooking.app/script/script.js" ${
        username ? `bot="${username}"` : `bot="${botId}"`
      }></script>`;
      setScriptTag(scriptString);
    }
  }, [isBotCreated, botId]);

  const handleUpdateBot = async () => {
    setLoading(true);
    try {
      const vendorToken = sessionStorage.getItem("VendorToken");
      const persistedData = JSON.parse(sessionStorage.getItem("botData"));
      const updatedData = {};
      if (chatHeader !== persistedData.botname) {
        updatedData.botname = chatHeader;
      }
      if (bgColor !== persistedData.bot_color_code) {
        updatedData.bot_color_code = bgColor;
      }
      if (welcomeMessage !== persistedData.welcome_message) {
        updatedData.welcome_message = welcomeMessage;
      }

      // Check and update the Bot Organization Type (ensure correct comparison)
      if (String(organizationType) !== String(persistedData.bot_category_id)) {
        updatedData.bot_category_id = String(organizationType);
      }

      if (selectedAvatar !== persistedData.avatar) {
        updatedData.avatar = selectedAvatar;
      }
      if (selectedPaymentGateway !== persistedData.payment_gateway_id) {
        updatedData.payment_gateway_id = selectedPaymentGateway;
      }
      const currentSupportedLangs = activeLanguages
        .map((language) => language.code)
        .join(",");
      if (currentSupportedLangs !== persistedData.supported_langs) {
        updatedData.supported_langs = currentSupportedLangs;
      }
      if (defaultLanguage?.toLowerCase() !== persistedData.default_lang) {
        updatedData.default_lang = defaultLanguage?.toLowerCase();
      }
      if ((scriptTag || "") !== persistedData.script_tag) {
        updatedData.script_tag = scriptTag || "";
      }
      const response = await _update_bot(botId, updatedData, vendorToken);

      if (response.status) {
        toast.success("Bot updated successfully!");
      } else {
        toast.error(response?.data?.message || "Failed to update bot");
      }
    } catch (error) {
      console.error("Error updating bot:", error);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while updating the bot"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPaymentGateway) {
      setPaymentMethodError("");
    }
  }, [selectedPaymentGateway]);

  const handleCategoryListAndPaymentGateways = async () => {
    try {
      const token = sessionStorage.getItem("VendorToken");
      const categories_response = await _vendorCategories();
      const payment_gateways_response = await _getPaymentGateways(token);
      if (categories_response.status) {
        setCategoryList(categories_response?.data?.categories);
      }

      if (payment_gateways_response.status) {
        setPaymentGateways(payment_gateways_response?.data?.payment_gateways);
        setSelectedPaymentGateway(
          payment_gateways_response?.data?.payment_gateways[0]
            ?.payment_gateway_id
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCategoryListAndPaymentGateways();
  }, []);

  const fetchSupportedLanguages = async () => {
    try {
      const token = sessionStorage.getItem("VendorToken");
      const response = await _getActiveLanguage({ accessToken: token });
      if (response.status) {
        setFetchedLanguages(response?.data);
      }
    } catch (error) {
      console.log("Error fetching languages:", error);
    }
  };

  const handleColorChange = (color) => {
    setBgColor(color);
    setSelectedColor(color);
  };

  const handleWelcomeMessageChange = (e) => {
    setWelcomeMessage(e.target.value);
  };

  const handleChatHeaderChange = (e) => {
    setIsHeaderLoading(false);
    setChatHeader(e.target.value);
  };

  const handleAvatarSelection = (avatar) => {
    setSelectedAvatar(avatar);
    setAvatarError("");
  };

  const handleOrganizationTypeChange = (e) => {
    const isAdmin = JSON.parse(sessionStorage.getItem("isAdmin")); // Check admin status

    if (isBotCreated && !isAdmin) {
      toast.error(
        "Only admins can change the organization type after the bot is created."
      );
      return; // Ignore changes
    }

    setOrganizationType(e.target.value); // Update state if valid
  };

  const handleBotCreation = async () => {
    let hasError = false;
    setErrorMessage("");
    setAvatarError("");
    setLanguageError("");
    setOrganizationTypeError("");
    setPaymentMethodError("");

    // Check if an avatar is selected
    if (!selectedAvatar) {
      setAvatarError("Please select an avatar.");
      hasError = true;
    }
    if (!organizationType) {
      setOrganizationTypeError("Please select a bot organization type.");
      hasError = true;
    }
    if (activeLanguages?.length === 0) {
      setLanguageError("Please choose at least one language.");
      hasError = true;
    }
    if (!selectedPaymentGateway) {
      setPaymentMethodError("Please select a payment method.");
      hasError = true;
    }
    if (hasError) return;
    setLoading(true);
    try {
      const vendorToken = sessionStorage.getItem("VendorToken");

      const body = {
        botname: chatHeader,
        logo: logo || logoLink,
        enable_audio_response: audioEnabled,
        tone: "formal",
        bot_behavior_prompt: "hello",
        bot_color_code: bgColor,
        currency: "inr",
        lang: "english",
        welcome_message: welcomeMessage,
        bot_category_id: String(organizationType),
        avatar: selectedAvatar,
        payment_gateway_id: selectedPaymentGateway,
        supported_langs: activeLanguages.map((lang) => lang.code).join(","),
        default_lang: defaultLanguage.toLowerCase(),
        script_tag: scriptTag || "",
      };
      const response = await _botCreation({ body, accessToken: vendorToken, userId });

      if (response.status) {
        setIsBotCreated(true);
        const newBotId = response?.data?.bot_id;
        setBotId(newBotId);

        // Now, set the script tag immediately after the bot is created
        const scriptString = `<script src="https://chatbotdev.plistbooking.app/script/script.js" ${
          username ? `bot="${username}"` : `bot="${botId}"`
        }></script>`;
        setScriptTag(scriptString);
        _update_bot(newBotId, { script_tag: scriptString }, vendorToken);

        // Store the bot data in localStorage
        sessionStorage.setItem("botData", JSON.stringify(body));
      } else {
        setIsBotCreated(false);
        toast.error(response?.data?.message);
      }
    } catch (error) {
      setIsBotCreated(false);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCopyScript = () => {
    navigator.clipboard.writeText(scriptTag);
    alert("Script copied to clipboard!");
  };

  const handleCopyURL = () => {
    if (!urlTag) {
      alert("URL is not available to copy.");
      return;
    }
    navigator.clipboard
      .writeText(urlTag)
      .then(() => {
        alert("URL Copied");
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
        alert("Failed to copy the URL.");
      });
  };

  const openLanguageModal = () => {
    setIsModalOpen(true);
    if (fetchedLanguages.length === 0) {
      fetchSupportedLanguages();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleLanguageClick = (selectedLanguage) => {
    const currentActiveLanguages = activeLanguages || [];

    const isLanguageActive = currentActiveLanguages.some(
      (language) => language.code === selectedLanguage.code
    );

    if (isLanguageActive) {
      setActiveLanguages(
        currentActiveLanguages.filter(
          (language) => language.code !== selectedLanguage.code
        )
      );
    } else {
      setActiveLanguages([...currentActiveLanguages, selectedLanguage]);
    }
    setLanguageError("");
  };

  const handlePaymentGatewayChange = (event) => {
    setSelectedPaymentGateway(event.target.value);
    setPaymentMethodError(""); // Clear payment method error when a gateway is selected
  };

  async function getBotDetails() {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("VendorToken");
      const botDetails = await _getBotDetails({ accessToken: token, userId });
      console.log("dhjfhsdjdjhdjhfjdhjd", botDetails.data)
      sessionStorage.setItem("botData", JSON.stringify(botDetails.data));
      if (botDetails?.status) {
        setIsBotCreated(true);
        setSelectedBot(botDetails.data);
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        if (botDetails.data?.botname) {
          setChatHeader(botDetails.data.botname);
        } else {
          setChatHeader(
            profileData.first_name
              ? `Welcome to ${profileData.first_name}'s Chat`
              : "Welcome to Chat"
          );
        }
        setSelectedAvatar(botDetails.data.avatar);
        setOrganizationType(String(botDetails.data.bot_category));
        setBgColor(botDetails.data.bot_color_code);
        setWelcomeMessage(botDetails.data.welcome_message);
        setLogoLink(botDetails.data.logo);
        setAudioEnabled(botDetails.data.enable_audio_response);
        if (Array.isArray(botDetails.data.supported_langs)) {
          setActiveLanguages(botDetails.data.supported_langs);
        }
  
        // Set default language, ensuring it matches the format from the API
        if (botDetails.data.default_lang) {
          setDefaultLanguage(botDetails.data.default_lang); // This is already in the correct format ('en')
        }
        setSelectedPaymentGateway(botDetails.data.payment_gateway);
        setScriptTag(botDetails.data.script_tag);
        setErrorMessage("");
        setBotId(botDetails.data.bot_id);
        setUsername(botDetails.data.username);
        setKnowledgeSourceProvide(
          botDetails.data.is_knowledge_api_source_provided
        );
      }
    } catch (error) {
      if (error.status === 404) {
        toast.success(error?.response?.data?.message);
      } else if (
        error?.response?.data?.message ==
        "User not found with id: " + userId
      ) {
        window.location.href = "/";
      } else {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (categoryList?.length > 0) {
      getBotDetails();
    }
  }, [categoryList]);

  // Update selectedCategoryName when organizationType or categoryList changes
  useEffect(() => {
    if (organizationType && categoryList.length > 0) {
      const selectedCategory = categoryList.find(
        (cat) => String(cat.category_id) === String(organizationType)
      );
      if (selectedCategory) {
        setSelectedCategoryName(selectedCategory.category_name);
      } else {
        setSelectedCategoryName("");
      }
    }
  }, [organizationType, categoryList]);

  const handleUsernameChange = (e) => {
    const newUsername = e.target.value;
    setUsername(newUsername);
    setUsernameError(""); // Clear any previous errors
  };

  // Function to validate username
  const validateUsername = () => {
    if (!username) {
      setUsernameError("Username is required");
      return false;
    }

    // Username validation rules
    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!usernameRegex.test(username)) {
      setUsernameError(
        "Username must be 3-20 characters long and can only contain letters, numbers, and underscores"
      );
      return false;
    }

    return true;
  };

  // Function to update username

  // Function to update username
  const handleUpdateUsername = async () => {
    // Validate username first
    if (!validateUsername()) {
      return;
    }
    setLoading(true);
    try {
      const vendorToken = sessionStorage.getItem("VendorToken");

      const response = await _vendorUserName({
        body: { username },
        accessToken: vendorToken,
        user_id: userId,
      });

      if (response.status) {
        toast.success("Username updated successfully!");

        // Store username in localStorage
        const userData = JSON.parse(sessionStorage.getItem("userData")) || {};
        userData.username = username;
        sessionStorage.setItem("userData", JSON.stringify(userData));
      } else {
        toast.error(response?.data?.message || "Failed to update username");
        setUsernameError(response?.data?.message || "Username update failed");
      }
    } catch (error) {
      console.error("Error updating username:", error);
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while updating the username"
      );
      setUsernameError(
        error?.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  useEffect(() => {
    // Check localStorage for username on component mount
    if (userData && userData.username) {
      setUsername(userData.username);

      // setIsUsernamePermanent(true); // Assume it's permanent if it exists in localStorage
    }
  }, []);

  useEffect(() => {
    if (activeLanguages?.length > 0 && (!defaultLanguage || defaultLanguage === "")) {
      setDefaultLanguage(activeLanguages[0]?.code);
    }
  }, [activeLanguages, defaultLanguage]);

  return (
    <>
      <div className="">
        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <p>Loading...</p> {/* Replace with a spinner component if needed */}
          </div>
        ) : (
          <>
            <ToastContainer />
            {/* <div className="tabsView">
          <button
            className={activeTab === "dashboard" ? "active" : ""}
            onClick={() => handleTabChange("dashboard")}
          >
            Bot Appearance
          </button>
          <button
            className={activeTab === "knowledge" ? "active" : ""}
            onClick={() => handleTabChange("knowledge")}
          >
            Bot Knowledge
          </button>
        </div> */}

            <div>
              {activeTab === "dashboard" && (
                <div>
                  <div className="mx-auto flex justify-between">
                    <div className="w-full">
                      <div className="mt-4">
                        <div className="rounded-md bg-white p-4 shadow">
                          <h1 className="text-xl text-[#666]">
                            <b className="align-center flex gap-2">
                              <FaRegUserCircle /> Identity
                            </b>
                          </h1>
                          <hr className="my-3" />
                          <div className="mb-4">
                            <label className="text-black-800 block font-bold">
                              Username
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                required
                                type="text"
                                className={`mt-1 w-full rounded border p-2 ${
                                  usernameError ? "border-red-500" : ""
                                }`}
                                placeholder="Enter your username"
                                value={username}
                                onChange={handleUsernameChange}
                              />
                              <button
                                onClick={handleUpdateUsername}
                                className="mt-1 whitespace-nowrap rounded bg-blue-500 px-4 py-2 text-white"
                              >
                                Save
                              </button>
                            </div>
                            {usernameError && (
                              <div className="mt-2">
                                <p className="text-sm text-red-500">
                                  {usernameError}
                                </p>
                              </div>
                            )}
                            {/* <div
                          style={{
                            fontSize: "12px",
                            letterSpacing: "1px",
                            color: "gray",
                          }}
                        >
                          {isUsernamePermanent
                            ? "Your username is set and cannot be changed"
                            : "This can be updated only once"}
                        </div> */}
                          </div>
                          <div className="mb-4">
                            <label className="text-black-800 block font-bold">
                              BOT Name
                            </label>
                            <input
                              required
                              type="text"
                              className="mt-1 w-full rounded border p-2"
                              placeholder={`Welcome to ${
                                profileData?.first_name || "User"
                              }'s Chat`}
                              value={
                                isHeaderLoading ? "Loading..." : chatHeader
                              }
                              onChange={handleChatHeaderChange}
                              disabled={isHeaderLoading}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="text-black-800 block font-bold">
                              Audio Responses
                            </label>
                            <div className="mt-1">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={audioEnabled}
                                  onChange={handleAudioToggle}
                                />
                                <span className="ml-1">
                                  Enable Audio Responses
                                </span>
                              </label>
                            </div>
                          </div>

                          <div className="mt-4">
                            <label className="text-black-800 block font-bold">
                              Welcome Message
                            </label>
                            <input
                              type="text"
                              className="mt-1 w-full rounded border p-2"
                              placeholder="Hi! How can we help?"
                              value={welcomeMessage}
                              onChange={handleWelcomeMessageChange}
                            />
                          </div>
                          <div className="mt-4">
                            <label className="text-black-800 block font-bold">
                              Bot Organization Type
                            </label>
                            <select
                              className="mt-1 w-full rounded border p-2"
                              value={organizationType}
                              onChange={handleOrganizationTypeChange}
                              disabled={
                                isBotCreated &&
                                !JSON.parse(sessionStorage.getItem("isAdmin"))
                              }
                            >
                              <option value="" disabled>
                                Select Organization Type
                              </option>
                              {categoryList?.map((type, index) => (
                                <option key={index} value={type?.category_id}>
                                  {type?.category_name}
                                </option>
                              ))}
                            </select>
                            {organizationTypeError && (
                              <div className="mt-2">
                                <p className="text-sm text-red-500">
                                  {organizationTypeError}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {botId &&
                          (selectedCategoryName === "Plist Booking Site" ||
                            selectedCategoryName ===
                              "Plist Partner Organization") && (
                            <ApiKnowledgeSource
                              botId={botId}
                              setIsKnowledgeApi={setIsKnowledgeApi}
                              knowledgeSourceProvide={knowledgeSourceProvide}
                              setKnowledgeSourceProvide={
                                setKnowledgeSourceProvide
                              }
                              prompts={prompts}
                              setPrompts={setPrompts}
                              flows={flows}
                              setFlows={setFlows}
                              selectedPrompts={selectedPrompts}
                              setSelectedPrompts={setSelectedPrompts}
                              selectedFlows={selectedFlows}
                              setSelectedFlows={setSelectedFlows}
                            />
                          )}
                        {botId && (
                          <PromptGeneration
                            botId={botId}
                            prompts={prompts}
                            setPrompts={setPrompts}
                            flows={flows}
                            setFlows={setFlows}
                            selectedPrompts={selectedPrompts}
                            setSelectedPrompts={setSelectedPrompts}
                            selectedFlows={selectedFlows}
                            setSelectedFlows={setSelectedFlows}
                          />
                        )}
                        <div className="mt-4 rounded-md bg-white p-4 shadow">
                          <h1 className="text-xl text-[#666]">
                            <b className="align-center flex gap-2">
                              <FaUserEdit /> Customisation
                            </b>
                          </h1>
                          <hr className="my-3" />

                          <div className="pb-4">
                            <div>
                              <b>Choose theme</b>
                            </div>
                            <div className="">
                              <div className="themeHover ml-auto flex space-x-2">
                                <div
                                  className={`h-8 w-8 cursor-pointer rounded-full bg-blue-500 ${
                                    selectedColor === "#3B82F6" ? "active" : ""
                                  }`}
                                  onClick={() => handleColorChange("#3B82F6")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer rounded-full bg-red-500 ${
                                    selectedColor === "#EF4444" ? "active" : ""
                                  }`}
                                  onClick={() => handleColorChange("#EF4444")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer rounded-full bg-green-500 ${
                                    selectedColor === "#10B981" ? "active" : ""
                                  }`}
                                  onClick={() => handleColorChange("#10B981")}
                                ></div>
                                <div
                                  className={`h-8 w-8 cursor-pointer rounded-full bg-purple-500 ${
                                    selectedColor === "#8B5CF6" ? "active" : ""
                                  }`}
                                  onClick={() => handleColorChange("#8B5CF6")}
                                ></div>
                                <input
                                  type="color"
                                  className={`h-8 w-8 cursor-pointer ${
                                    selectedColor === bgColor ? "active" : ""
                                  }`}
                                  value={bgColor}
                                  onChange={(e) =>
                                    handleColorChange(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-4 rounded bg-[#E0E5F2] p-2.5">
                            <label className="text-black-800 block font-bold">
                              Logo
                            </label>
                            <div className="mt-1">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleLogoUpload}
                                className="w-full rounded border p-2"
                              />
                              <p className=" my-3 text-center text-gray-900">
                                OR
                              </p>
                              <input
                                type="text"
                                placeholder="Enter logo URL"
                                value={logoLink}
                                onChange={handleLogoLinkChange}
                                className="mt-1 w-full rounded border p-2"
                              />
                            </div>
                          </div>
                          <label className="text-black-800 block font-bold">
                            Select Avatar
                          </label>
                          <div className="mt-2 flex space-x-2">
                            {avatarOptions.map((avatar, index) => (
                              <img
                                key={index}
                                src={avatar}
                                alt={`Avatar ${index + 1}`}
                                className={`h-12 w-12 cursor-pointer rounded-full border-2 ${
                                  selectedAvatar === avatar
                                    ? "border-blue-500"
                                    : "border-gray-300"
                                }`}
                                onClick={() => handleAvatarSelection(avatar)}
                              />
                            ))}
                          </div>
                          <div>
                            {avatarError && (
                              <div className="mt-2">
                                <p className="text-sm text-red-500">
                                  {avatarError}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-4 rounded-md bg-white p-4 shadow">
                          <h1 className="text-xl text-[#666]">
                            <b className="align-center flex gap-2">
                              <IoIosGlobe /> Language
                            </b>
                          </h1>
                          <hr className="my-3" />
                          <div className="languageParent">
                            <div>
                              <label className="text-black-800 block cursor-pointer font-bold">
                                Choose Language
                              </label>
                              <div
                                className="langSelect align-center flex gap-2 align-middle"
                                onClick={openLanguageModal}
                              >
                                <label className="">Languages</label>
                                <FaGlobe />
                              </div>

                              {isModalOpen && (
                                <div className="modal-overlay">
                                  <div className="modal">
                                    <h1>
                                      <b>Select multiple languages</b>
                                    </h1>
                                    <br />
                                    <button
                                      className="close"
                                      onClick={closeModal}
                                    >
                                      <IoMdClose />
                                    </button>
                                    <div className="languageList">
                                      <ul>
                                        {fetchedLanguages.map((language) => (
                                          <li
                                            key={language.code}
                                            className={
                                              activeLanguages?.some(
                                                (activeLang) =>
                                                  activeLang.code ===
                                                  language.code
                                              )
                                                ? "active"
                                                : ""
                                            }
                                            onClick={() =>
                                              handleLanguageClick(language)
                                            }
                                          >
                                            {language.name} ({language.code})
                                          </li>
                                        ))}
                                      </ul>
                                    </div>

                                    {/* <div className="text-right">
                                  <button
                                    className="saveBtn rounded bg-blue-500 py-2 px-4 text-white"
                                    onClick={handlehandleUpdateBotSupportLang}
                                  >
                                    Save
                                  </button>
                                </div> */}

                                    {/* Loader */}
                                    {loading && (
                                      <div className="LangLoader">
                                        Saving...
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              <div>
                                <b>Default language</b>
                              </div>
                              <select
  className="Deflanguage"
  value={defaultLanguage}
  onChange={(e) => setDefaultLanguage(e.target.value)}
>
  <option value="" disabled>
    Default Lang
  </option>
  {activeLanguages?.map((language) => (
    <option
      key={language.code}
      value={language.code}  // This should match the default_lang format
    >
      {language.name} ({language.code})
    </option>
  ))}
</select>
                            </div>
                          </div>
                          <div style={{ marginTop: "", paddingLeft: "" }}>
                            {languageError && (
                              <div className="mt-2 ">
                                <p className="text-sm text-red-500">
                                  {languageError}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {isBotCreated ? (
                          <div className="mt-4 rounded-md bg-white p-4 shadow">
                            <h1 className="text-xl text-[#666]">
                              <b className="align-center flex gap-2">
                                <FaRobot /> Bot Knowledge
                              </b>
                            </h1>
                            <hr className="my-3" />
                            <KnowledgeUpdateForm botId={botId} />
                          </div>
                        ) : (
                          <div className="mt-4 rounded-md bg-gray-100 p-4 shadow">
                            <h1 className="text-xl text-[#666]">
                              <b className="align-center flex gap-2">
                                <FaRobot /> Bot Knowledge
                              </b>
                            </h1>
                            <hr className="my-3" />
                            <p className="text-gray-500">
                              Bot Knowledge is disabled until the bot is
                              created.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full">
                      <div
                        className="sticky flex h-[26rem] flex-col items-center p-4"
                        style={{ top: "65px" }}
                      >
                        {isBotCreated && (
                          <div
                            className="rounded-md bg-white p-4 shadow"
                            style={{ width: "400px" }}
                          >
                            {/* <p className="text-sm">
                          Your bot has been created with ID: <b> {botId}</b>
                        </p> */}
                            <p className="text-sm font-bold">
                              Embed this script on your site:
                            </p>
                            <div style={{ position: "relative" }}>
                              <div
                                className="rounded bg-gray-100 p-2"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "auto",
                                  paddingRight: '55px'
                                }}
                              >
                                <code className="whitespace-break-spaces text-xs">
                                  {scriptTag}
                                </code>
                              </div>
                              <button
                                style={{
                                  position: "absolute",
                                  right: "0",
                                  top: "0",
                                  height: "100%",
                                }}
                                onClick={handleCopyScript}
                                className="rounded bg-green-500 py-2 px-4 text-sm text-white"
                              >
                                <FaRegCopy />
                              </button>
                            </div>

                            <p className="mt-2 text-sm font-bold">
                              Chat widget URL
                            </p>
                            <div style={{ position: "relative" }}>
                              <div
                                className="rounded bg-gray-100 p-2"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "auto",
                                  paddingRight: '55px'
                                }}
                              >
                                <code className="whitespace-break-spaces text-xs">
                                  {urlTag}
                                </code>
                              </div>
                              <button
                                style={{
                                  position: "absolute",
                                  right: "0",
                                  top: "0",
                                  height: "100%",
                                }}
                                onClick={handleCopyURL}
                                className="rounded bg-green-500 py-2 px-4 text-sm text-white"
                              >
                                <FaRegCopy />
                              </button>
                            </div>

                            {/* <a 
                          className="mt-3 bg-green-500"
                          style={{padding:'8px 10px',color:'white', display:'inline-block', width:'100%', textAlign:'center', borderRadius:'0.375rem'}}
                        href={`https://static-plist-staging.edully.com/?bot-id=${botId}`} target="_blank"></a> */}
                            <p
                              style={{
                                color: "#666",
                                fontSize: "12px",
                                letterSpacing: "0.2px",
                              }}
                            >
                              This link will generate the bot{" "}
                            </p>
                          </div>
                        )}
                        <br />
                        {/* <div className="mb-2">Bot Agent Preview</div> */}
                        <div
                          className="flex h-full w-80 flex-col rounded-lg bg-white shadow-md"
                          style={{ width: "400px" }}
                        >
                          <div
                            className="rounded-t-xl p-4 text-center text-white"
                            style={{ backgroundColor: bgColor }}
                          >
                            {chatHeader}
                          </div>
                          <div
                            className="flex-1 p-2.5"
                            style={{ minHeight: "200px" }}
                          >
                            <div className="max-w-60 flex w-fit">
                              {selectedAvatar && (
                                <img
                                  src={selectedAvatar}
                                  alt="Selected Avatar"
                                  className="inline-block h-8 w-8 rounded-full"
                                />
                              )}
                              <div className="ml-1 mr-1 rounded-3xl rounded-tl-none bg-[#f1f1f1] p-3 text-sm">
                                {welcomeMessage}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <input
                              type="text"
                              disabled
                              className="w-full rounded  p-2 text-sm"
                              placeholder="Type here and press enter..."
                            />
                          </div>
                        </div>
                        <div className="mt-4 flex w-full	flex-col items-center justify-center p-2">
                          {isBotCreated ? (
                            <button
                              onClick={handleUpdateBot}
                              className="rounded-md bg-blue-500 py-2 px-4 text-white"
                              style={{ width: "400px" }}
                            >
                              Update Bot
                            </button>
                          ) : (
                            <button
                              onClick={handleBotCreation}
                              className="rounded-md bg-blue-500 py-2 px-4 text-white"
                              style={{ width: "400px" }}
                            >
                              Create Bot
                            </button>
                          )}
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* {activeTab === "knowledge" && <KnowledgeUpdateForm botId={botId} />} */}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Marketplace;
