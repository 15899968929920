import InputField from "components/fields/InputField";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { _resetPassword } from "Services/api";

export default function ResetPassword() {
  const location = useLocation(); // Get the current location
  const queryParams = new URLSearchParams(location.search); // Parse the query parameters
  const token = queryParams.get("token"); // Extract the 'token' parameter

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleResetPassword = async () => {
    // Reset errors and message
    setErrors({});
    setMessage("");

    // Validation
    if (!password) {
      setErrors({ password: "Password is required" });
      return;
    }
    if (password.length < 8) {
      setErrors({ password: "Password must be at least 8 characters long" });
      return;
    }
    if (!confirmPassword) {
      setErrors({ confirmPassword: "Please confirm your password" });
      return;
    }
    if (password !== confirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match" });
      return;
    }

    setLoading(true);

    try {
      const response = await _resetPassword({
        body: { token, new_password: password }, // Pass the token and password
      });

      if (response.success) {
        setMessage("Password reset successfully! You can now log in.");
      } else {
        setErrors({ general: response.message || "Failed to reset password" });
      }
    } catch (error) {
      setErrors({ general: error.message || "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Loader Overlay */}
      {loading && (
        <div className="loader-overlay">
          <span className="loader"></span>
        </div>
      )}

      {/* Reset Password Section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white mt-5">
          Reset Password
        </h4>

        {/* General Error or Success Message */}
        {errors.general && (
          <div className="text-red-600 text-sm mb-4">{errors.general}</div>
        )}
        {message && (
          <div className="text-green-600 text-sm mb-4">{message}</div>
        )}

        <InputField
          variant="auth"
          extra="mb-3"
          label="Enter your New Password"
          placeholder="Enter your New Password"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {errors.password && (
          <span className="text-red-600 text-sm">{errors.password}</span>
        )}

        <InputField
          variant="auth"
          extra="mb-3"
          label="Confirm your New Password"
          placeholder="Confirm your New Password"
          id="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {errors.confirmPassword && (
          <span className="text-red-600 text-sm">{errors.confirmPassword}</span>
        )}

        <button
          onClick={handleResetPassword}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Submit
        </button>

        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Already registered?
          </span>
          <a
            href="/auth/sign-in"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
}
