import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { ProfileProvider } from "context/ProfileContext";
import { WebSocketProvider } from "context/WebSocketContext"; // Import the WebSocketProvider
import { ToastContainer } from "react-toastify";

const App = () => {
  const [userId, setUserId] = useState(null);
  const [webSocketUrl, setWebSocketUrl] = useState("");

  const vendorToken = sessionStorage.getItem("VendorToken");
  const userData = JSON.parse(sessionStorage.getItem("userData") || "{}"); // Parse userData from localStorage
  // const userId = userData.user_id; // Extract user_id from userData
  // const webSocketUrl = `wss://plist-staging.edully.com/api/ws/agent/${userId}`;

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData") || "{}");
    if (userData.user_id) {
      setUserId(userData.user_id);
      setWebSocketUrl(`wss://plist-staging.edully.com/api/ws/agent/${userData.user_id}`);
    }
  }, []);


  return (
    <ProfileProvider>
      <WebSocketProvider url={webSocketUrl}>
        <ToastContainer />
        <Routes>
          {vendorToken ? (
            <>
              <Route path="admin/*" element={<AdminLayout />} />
              <Route
                path="/"
                element={<Navigate to={`/admin/${userId}`} replace />}
              />
            </>
          ) : (
            <Route path="auth/*" element={<AuthLayout />} />
          )}
          <Route
            path="/"
            element={
              vendorToken ? (
                <Navigate to={`/admin/${userId}`} replace />
              ) : (
                <Navigate to="/auth/sign-in" replace />
              )
            }
          />
          <Route
            path="*"
            element={
              vendorToken ? (
                <Navigate to={`/admin/${userId}`} replace />
              ) : (
                <Navigate to="/auth/sign-in" replace />
              )
            }
          />
        </Routes>
      </WebSocketProvider>
    </ProfileProvider>
  );
};

export default App;
