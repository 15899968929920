// import React, { useState } from "react";
// import ChatSidebar from "./ChatSidebar";
// import ChatWindow from "./ChatWindow";
// import "./chat.scss";

// const Help = () => {
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [conversations, setConversations] = useState({});

//   const users = [
//     { id: 1, name: "Alice" },
//     { id: 2, name: "Bob" },
//     { id: 3, name: "Charlie" },
//   ];

//   const handleUserSelect = (user) => {
//     setSelectedUser(user);
//   };

//   const handleAddMessage = (message) => {
//     if (selectedUser) {
//       setConversations((prevConversations) => {
//         const userId = selectedUser.id;
//         const newMessages = [
//           ...(prevConversations[userId] || []),
//           { text: message, sender: "You" },
//         ];
//         // Static response
//         setTimeout(() => {
//           setConversations((prevConversations) => ({
//             ...prevConversations,
//             [userId]: [
//               ...newMessages,
//               {
//                 text: "This is a static response from the bot.",
//                 sender: "Bot",
//               },
//             ],
//           }));
//         }, 1000); // Adjust the delay as needed
//         return { ...prevConversations, [userId]: newMessages };
//       });
//     }
//   };
//   return (
//     <div className="mt-3">
//       <div className="shadow-lg" style={{ backgroundColor: "white" }}>
//         <div className="chatIn flex">
//           <ChatSidebar
//             users={users}
//             onUserSelect={handleUserSelect}
//             selectedUser={selectedUser}
//           />
//           <ChatWindow
//             user={selectedUser}
//             messages={conversations[selectedUser?.id] || []}
//             onAddMessage={handleAddMessage}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Help;

import React, { useEffect, useState } from "react";
import ChatSidebar from "./ChatSidebar";
import ChatWindow from "./ChatWindow";
import UserTable from "./UserTable"; // Import the new UserTable component
import "./chat.scss";
import { _getEscalationRequests, _updateEscalationRequest, _getEscalationHistory } from "Services/api";

const Help = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [showChat, setShowChat] = useState(false); // New state to control UI
  const [users, setUsers] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [clientId, setClientID] = useState()

  const accessToken = sessionStorage.getItem('VendorToken');
  useEffect(() => {
    const botDetails = JSON.parse(sessionStorage.getItem("botData"));
    const fetchEscalationRequests = async () => {
      try {
        const response = await _getEscalationRequests({
          bot_id: botDetails.bot_id || botDetails.unique_id,
          page: 1,
          limit: 10,
          room_no: '',
          accessToken,
        });
        const fetchedRequests = response.data.escalation_requests;
        const mappedUsers = fetchedRequests.map((request) => ({
          id: request.room_no,
          name: request.full_name,
          email: request.email,
          phone: request.phone,
          issue: request.issue || "No issue provided",
          roomNo: request.room_no,
          isResolved: request.is_resolved,
          flowType: request.flow_type,
          comment: request.comment || "No comment",
          escalation_request_id: request.escalation_request_id,
          is_read: request.is_read,
          user_id: request.user_id,
        }));
        setUsers(mappedUsers);
      } catch (error) {
        console.error("Failed to fetch escalation requests", error);
      }
    };

    fetchEscalationRequests();
  }, []);

  const updateEscalationRequest = async (escalationId) => {
    try {
      const payload = { is_read: true };

      const response = await _updateEscalationRequest({
        escalationId,
        payload,
        accessToken
      });
    } catch (error) {
      console.error("Failed to update escalation request", error);
    }
  };
  const fetchEscalationHistory = async (escalationId) => {
    try {
      const response = await _getEscalationHistory({
        accessToken: sessionStorage.getItem('VendorToken'),
        escalationId: escalationId,
      });
      if (response.status) {
        setChatId(response.data.escalation_chat_id);
        const historyMessages = response.data.history.map((item) => {
          return {
            text: item.message,
            sender: item.user_type === 'chatbot_user' ? 'Bot' : 'You',
          };
        });
        setConversations((prevConversations) => [
          ...historyMessages,
        ]);
      }
    } catch (error) {
      console.error("Failed to fetch escalation history", error);
    }
  };

  const handleUserSelect = async (user) => {
    setSelectedUser(user);
    setShowChat(true);
    setClientID(user.user_id)
    // Fetch escalation history
    await fetchEscalationHistory(user.escalation_request_id);
    await updateEscalationRequest(user.escalation_request_id);
  };
  const handleAddMessage = (messageObj) => {
    setConversations((prevConversations) => [
      ...prevConversations,
      messageObj, // Directly add the message object
    ]);
  };

  return (
    <div className="mt-3">
      <div className="shadow-lg" style={{ backgroundColor: "white" }}>
        <div className="chatIn flex">
          {!showChat ? (
            users.length > 0 ? (
              <UserTable users={users} onUserSelect={handleUserSelect} />
            ) : (
              <div className="flex justify-center items-center w-full h-64">
                <p className="text-gray-500 text-lg">No requests found.</p>
              </div>
            )
          ) : (
            <div className="flex w-full">
              <ChatWindow
                chatId={chatId}
                user={selectedUser}
                messages={conversations}
                onAddMessage={handleAddMessage}
                clientId={clientId}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Help;
