import React, { useEffect, useState } from 'react';
import { Card, CardBody } from "@material-tailwind/react";
import { _getLanguage, _toggleLanguage } from 'Services/api';
import { toast } from 'react-toastify';

const Language = () => {
  const [fetchedLanguages, setFetchedLanguages] = useState([]);
  const [originalLanguages, setOriginalLanguages] = useState([]); // Track original state
  const [languageError, setLanguageError] = useState("");
  const [saving, setSaving] = useState(false); // Add saving state

  useEffect(() => {
    const fetchSupportedLanguages = async () => {
      try {
        const token = sessionStorage.getItem("VendorToken");
        const response = await _getLanguage({ accessToken: token });
        if (response.status) {
          const languagesWithCheckedStatus = response.data.map(language => ({
            ...language,
            is_checked: language.is_checked || false, // Use API value or default to false
          }));
          setFetchedLanguages(languagesWithCheckedStatus);
          setOriginalLanguages(languagesWithCheckedStatus); // Set original state
        }
      } catch (error) {
        console.log("Error fetching languages:", error);
      }
    };

    fetchSupportedLanguages();
  }, []);

  const handleLanguageClick = (selectedLanguage) => {
    setFetchedLanguages((prevLanguages) =>
      prevLanguages.map((language) =>
        language.code === selectedLanguage.code
          ? { ...language, is_checked: !language.is_checked } // Toggle is_checked
          : language
      )
    );
    setLanguageError("");
  };

  const handleUpdateBotSupportLang = async () => {
    setSaving(true); // Start loader
    try {
      // Filter only the changed languages
      const updatedLanguages = fetchedLanguages.filter((language) => {
        const originalLanguage = originalLanguages.find(
          (origLang) => origLang.code === language.code
        );
        return originalLanguage?.is_checked !== language.is_checked;
      });

      if (updatedLanguages.length === 0) {
        toast.info("No changes to update");
        setSaving(false); // Stop loader
        return;
      }

      const vendorToken = sessionStorage.getItem("VendorToken");
      const languages = updatedLanguages.map((language) => ({
        language_id: language.language_id,
        is_active: language.is_checked, // Send updated status
      }));

      const body = { languages };
      const response = await _toggleLanguage(body, vendorToken);

      if (response.status) {
        toast.success("Languages updated successfully");
        setOriginalLanguages(fetchedLanguages); // Update original state
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setSaving(false); // Stop loader
    }
  };

  return (
    <div className="flex justify-center mt-4">
      <Card className="w-full max-w-4xl overflow-hidden mb-6">
        <CardBody className="p-5">
          <h2 className="mb-4 text-lg font-semibold flex items-center">
            Languages
            {saving && (
              <svg
                className="ml-2 w-5 h-5 animate-spin text-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8z"
                ></path>
              </svg>
            )}
          </h2>
          <hr className="mb-4" />
          <div className="overflow-y-auto max-h-80">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {fetchedLanguages.map((language) => (
                <div key={language.code} className="flex items-center">
                  <input
                    type="checkbox"
                    id={language.code}
                    className="mr-2"
                    checked={language.is_checked}
                    onChange={() => handleLanguageClick(language)}
                  />
                  <label
                    htmlFor={language.code}
                    className="text-gray-700"
                  >
                    {language.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <hr className="my-4" />
          <div className="text-right">
            <button
              className="rounded-md bg-blue-500 py-2 px-4 text-white"
              onClick={handleUpdateBotSupportLang}
              disabled={saving}
            >
              {saving ? "Saving..." : "Save"}
            </button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Language;
