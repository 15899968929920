import React, { useEffect, useState } from "react";
import {
  _updateBotKnowledgeDetails,
  _botKnowledgeStatus,
  _getBotDetails,
} from "Services/api";
import { SlRefresh } from "react-icons/sl";
import { MdOutlineRefresh } from "react-icons/md";
import { useParams } from "react-router-dom";

const KnowledgeUpdateForm = ({ botId }) => {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [lastUpdate, setLastUpdate] = useState();
  const [botStatus, setBotStatus] = useState(
    JSON.parse(sessionStorage.getItem("botStatus")) || null
  );
  const [refresh, setRefresh] = useState(
    JSON.parse(sessionStorage.getItem("refresh")) || false
  );
  const [taskId, setTaskId] = useState(
    sessionStorage.getItem("taskId") || null
  );
  const [checkStatusDisabled, setCheckStatusDisabled] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const accessToken = sessionStorage.getItem("VendorToken");
  const { id: userId } = useParams();

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const extractBaseUrl = (fullUrl) => {
    try {
      const parsedUrl = new URL(fullUrl);
      return parsedUrl.origin; // Ensures the trailing slash
    } catch {
      return fullUrl; // Return the original URL if parsing fails
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      // Retain the previously selected file if no new file is chosen
      return;
    }
    if (selectedFile && selectedFile.type !== "application/pdf") {
      setError("Only PDF files are allowed.");
      setFile(null);
    } else {
      setError("");
      setFile(selectedFile);
    }
  };

  const fetchBotDetails = async () => {
    try {
      setLoading(true);
      const botDetailsResponse = await _getBotDetails({
        botId,
        accessToken,
        userId,
      });
      setUrl(botDetailsResponse?.data?.knowledge_source_url);
      // setFile(botDetailsResponse?.data?.knowledge_source_file)
      setLastUpdate(
        botDetailsResponse?.data?.knowledge_source_last_updated_at
          ? formatDate(
              botDetailsResponse?.data?.knowledge_source_last_updated_at
            )
          : ""
      );
      if (botDetailsResponse?.data?.knowledge_source_name) {
        setRefresh(true);
        sessionStorage.setItem("refresh", true);
      }
    } catch (error) {
      console.error("Error fetching bot details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBotDetails();

    if (taskId) {
      fetchBotKnowledgeStatus(taskId);
    }
  }, []);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const fetchBotKnowledgeStatus = async (taskId) => {
    try {
      const statusResponse = await _botKnowledgeStatus(accessToken, taskId);
      const extractedUrl = extractBaseUrl(
        statusResponse.data.knowledge_source_url
      );
      setBotStatus(statusResponse.data);
      setUrl(extractedUrl);
      // setFile(statusResponse.data.knowledge_source_file);
      setLastUpdate(
        statusResponse?.data?.knowledge_source_last_updated_at
          ? formatDate(statusResponse?.data?.knowledge_source_last_updated_at)
          : ""
      );
      sessionStorage.setItem("botStatus", JSON.stringify(statusResponse.data));
      if (statusResponse.data.progress === "100%") {
        setCheckStatusDisabled(true);
      }
    } catch (error) {
      console.error("Error fetching bot knowledge status:", error);
    }
  };

  const handleSubmit = async () => {
    setError("");
    setSuccess("");

    if (!botId) {
      setError("Please create a bot first.");
      return;
    }

    if (!file && !url) {
      setError("Please select a file or enter a URL before submitting.");
      return;
    }
    try {
      setLoading(true);
      const response = await _updateBotKnowledgeDetails({
        botId,
        file,
        url,
        accessToken,
      });

      if (!response.status) {
        // setError(response.message);
        return;
      }
      // setFile(response?.data?.knowledge_source_file);
      setUrl(response?.data?.knowledge_source_url);
      setLastUpdate(
        response?.data?.knowledge_source_last_updated_at
          ? formatDate(response?.data?.knowledge_source_last_updated_at)
          : ""
      );
      setSuccess("Bot knowledge updated successfully!");
      setRefresh(true);
      setTaskId(response.data.task_id);

      // Persist in localStorage
      sessionStorage.setItem("refresh", true);
      sessionStorage.setItem("taskId", response.data.task_id);

      setCheckStatusDisabled(false);
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setError("");
    setSuccess("");
    setRefreshLoading(true);
    try {
      const response = await _updateBotKnowledgeDetails({
        botId,
        file,
        url,
        accessToken,
      });

      if (!response.status) {
        setError(response.message);
        return;
      }
      // setFile(response?.data?.knowledge_source_file);
      setUrl(response?.data?.knowledge_source_url);
      setLastUpdate(
        response?.data?.knowledge_source_last_updated_at
          ? formatDate(response?.data?.knowledge_source_last_updated_at)
          : ""
      );
      setSuccess("Bot knowledge refreshed successfully!");
      setRefresh(true);
      setTaskId(response.data.task_id);

      // Persist in localStorage
      sessionStorage.setItem("refresh", true);
      sessionStorage.setItem("taskId", response.data.task_id);

      setCheckStatusDisabled(false);
    } catch (err) {
      setError(
        err.response?.data?.message || "An error occurred during refresh."
      );
    } finally {
      setRefreshLoading(false);
    }
  };

  const handleCheckStatus = async () => {
    if (taskId) {
      try {
        setStatusLoading(true);
        await fetchBotKnowledgeStatus(taskId);
      } catch (error) {
        console.error("Error checking status:", error);
      } finally {
        setStatusLoading(false);
      }
    } else {
      setError("Task ID is not available.");
    }
  };

  return (
    <div>
      <div className="knowledge">
        <div className="upload-container">
          <p style={{ fontSize: "14px", color: "gray", marginBottom: "5px" }}>
            Please upload only PDF files.
          </p>
          <input
            type="file"
            className="upload-input"
            accept=".pdf"
            onChange={handleFileChange}
          />
          {botStatus?.knowledge_source_file && file && (
            <div>
              We have fetched the data from <b>{botStatus?.knowledge_source_file}</b>
            </div>
          )}
        </div>

        <div className="flex w-full items-center gap-2">
          <input
            type="text"
            className="text-input w-full"
            placeholder="Enter the URL"
            value={url}
            onChange={handleUrlChange}
          />
          <button
            className="submit-button"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center gap-2">
                <SlRefresh className={loading ? "spin" : ""} />
                Processing...
              </span>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>

      <div className="mt-4 flex items-center justify-between gap-3">
        {(botStatus?.status === "failed" ||
          (refresh && checkStatusDisabled)) && (
          <>
            <button
              className={`flex items-center whitespace-nowrap rounded bg-green-500 px-4 py-1 text-sm text-white transition-colors duration-300 ${
                refreshLoading ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={handleRefresh}
              disabled={refreshLoading}
            >
              {refreshLoading ? (
                <SlRefresh className="mr-2 animate-spin" />
              ) : (
                <MdOutlineRefresh className="mr-2" />
              )}
              Fetch Again
            </button>
            {botStatus?.status === "failed" && (
              <span className="text-sm text-red-500">
                Process failed. Please try again.
              </span>
            )}
          </>
        )}

        {taskId &&
          refresh &&
          botStatus?.status !== "failed" &&
          !checkStatusDisabled && (
            <button
              className={`flex items-center whitespace-nowrap rounded bg-blue-500 px-5 py-1 text-sm text-white transition-colors duration-300 hover:bg-blue-600 ${
                statusLoading ? "cursor-not-allowed opacity-50" : ""
              }`}
              onClick={handleCheckStatus}
              disabled={statusLoading}
            >
              {statusLoading ? (
                <>
                  <SlRefresh className="mr-2 animate-spin" />
                  <span>Loading</span>
                </>
              ) : (
                "Check Status"
              )}
            </button>
          )}

        <div className="leading-none">
          {botStatus &&
          botStatus?.status !== "failed" &&
          botStatus?.status !== "completed" ? (
            <span className="text-sm text-gray-900">
              Fetching knowledge from <b>{botStatus?.knowledge_source_file || url}</b> ({botStatus.progress || "0"})
              
            </span>
          ) : (
            (url || botStatus?.knowledge_source_file) &&
            lastUpdate && (
              <span className="text-sm text-gray-900">
                Last knowledge added at: <b>{lastUpdate}</b> from <b>{botStatus?.knowledge_source_file ? botStatus?.knowledge_source_file : url}</b>
              </span>
            )
          )}
        </div>
      </div>

      {error && (
        <p
          style={{
            color: "red",
            textAlign: "left",
            fontSize: "14px",
            marginTop: "5px",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default KnowledgeUpdateForm;
