import React, { createContext, useState, useEffect } from 'react';
import { _userProfile } from 'Services/api';

// Create a context
export const ProfileContext = createContext();

// Create a provider component
export const ProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState({});
  const [token, setToken] = useState(sessionStorage.getItem("VendorToken"));

  useEffect(() => {
    const getUserProfile = async () => {
      if (token) { // Check if VendorToken exists
        try {
          const userProfile = await _userProfile(token);
          setProfileData(userProfile?.data);
          sessionStorage.setItem("userData", JSON.stringify(userProfile?.data))
        } catch (error) {
          console.error("Failed to fetch user profile:", error);
        }
      }
    };

    // Listen for changes in the VendorToken
    const handleStorageChange = (event) => {
      if (event.key === "VendorToken") { // Only trigger on VendorToken change
        setToken(event.newValue);
      }
    };

    // Add event listener for storage changes
    window.addEventListener('storage', handleStorageChange);

    // Initial call to getUserProfile if token is already present
    getUserProfile();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [token]); // Run getUserProfile only when token changes

  return (
    <ProfileContext.Provider value={profileData}>
      {children}
    </ProfileContext.Provider>
  );
};
