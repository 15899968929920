import React, { useEffect, useState } from 'react';
import { Card, CardBody, Button } from "@material-tailwind/react";
import { _getAllPrompts, _updateAllPrompts } from 'Services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApplicableFlow = () => {
    const [data, setData] = useState(null);
    const [updatedItems, setUpdatedItems] = useState([]); // Track changed items
    const [savingCategories, setSavingCategories] = useState({}); // Track saving state for each category

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('VendorToken');
                const response = await _getAllPrompts({ accessToken });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleCheckboxChange = (category, subcategory, uniqueId) => {
        setData(prevData => ({
            ...prevData,
            [category]: {
                ...prevData[category],
                [subcategory]: prevData[category][subcategory].map(item =>
                    item.unique_id === uniqueId
                        ? { ...item, is_checked: !item.is_checked }
                        : item
                )
            }
        }));

        setUpdatedItems(prevItems => {
            const updatedIndex = prevItems.findIndex(item => item.unique_id === uniqueId);
            const newItem = { unique_id: uniqueId, category, subcategory };

            if (updatedIndex > -1) {
                return prevItems.map((item, index) => (index === updatedIndex ? newItem : item));
            }
            return [...prevItems, newItem];
        });
    };

    const handleUpdatePrompts = async (categoryKey) => {
        setSavingCategories(prevState => ({ ...prevState, [categoryKey]: true })); // Start loader for category
        const accessToken = sessionStorage.getItem('VendorToken');
        try {
            const categoryItems = updatedItems.filter(item => item.category === categoryKey);
    
            // Create payload with all prompts for the selected category
            const promptsPayload = {
                prompts: categoryItems.map(({ unique_id, subcategory }) => {
                    const subcategoryItem = data[categoryKey][subcategory].find(i => i.unique_id === unique_id);
                    const isChecked = subcategoryItem?.is_checked;
    
                    return {
                        prompt_id: unique_id,
                        vendor_org_type: categoryKey,
                        vendor_org_type_status: isChecked
                    };
                })
            };
    
            console.log("Payload to be sent:", promptsPayload); // Debug payload
    
            // Send the API call with the updated payload
            const response = await _updateAllPrompts({
                body: promptsPayload,
                accessToken
            });
    
            if (response?.status) {
                toast.success(response?.message);
            } else {
                toast.error("Failed to update. Please try again.");
            }
    
            // Remove updated items for this category
            setUpdatedItems(prevItems => prevItems.filter(item => item.category !== categoryKey));
        } catch (error) {
            console.error("Error updating prompts:", error);
            toast.error("An error occurred while updating.");
        } finally {
            setSavingCategories(prevState => ({ ...prevState, [categoryKey]: false })); // Stop loader for category
        }
    };
    

    const renderCheckboxes = (categoryData, categoryName) => (
        <div className="container mx-auto p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                {Object.keys(categoryData).map(subcategory => (
                    <div key={subcategory}>
                        <div><b>{subcategory.replace(/_/g, ' ')}</b></div>
                        <ul>
                            {categoryData[subcategory].map(item => (
                                <li key={item.unique_id}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={item.is_checked}
                                            onChange={() => handleCheckboxChange(categoryName, subcategory, item.unique_id)}
                                        /> {item.name}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );

    if (!data) return <div>Loading...</div>;

    return (
        <div>
            <ToastContainer />
            {Object.keys(data).map((categoryKey) => (
                <Card key={categoryKey} className="h-full w-full overflow-scroll mb-6">
                    <CardBody className="overflow-scroll p-5">
                        <h2 className='mb-2 flex items-center'>
                            <b>{categoryKey.replace(/_/g, ' ')}</b>
                            {savingCategories[categoryKey] && (
                                <svg
                                    className="ml-2 w-5 h-5 animate-spin text-blue-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8v8z"
                                    ></path>
                                </svg>
                            )}
                        </h2>
                        <hr className='mb-3' />
                        {renderCheckboxes(data[categoryKey], categoryKey)}
                        <hr className='mb-4' />
                        <div className='text-right'>
                            <Button
                                color="blue"
                                onClick={() => handleUpdatePrompts(categoryKey)}
                                disabled={savingCategories[categoryKey]} // Disable button while saving
                            >
                                {savingCategories[categoryKey] ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            ))}
        </div>
    );
};

export default ApplicableFlow;
