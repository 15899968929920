import InputField from "components/fields/InputField";
import { useState } from "react";
import { _vendorRegister } from "Services/api";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [apiResError, setApiResError] = useState();
  const navigate = useNavigate();

  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (!firstName) {
      tempErrors["firstName"] = "First Name is required";
      isValid = false;
    }

    if (!lastName) {
      tempErrors["lastName"] = "Last Name is required";
      isValid = false;
    }

    if (!email) {
      tempErrors["email"] = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors["email"] = "Email is invalid";
      isValid = false;
    }

    if (!phone) {
      tempErrors["phone"] = "Phone number is required";
      isValid = false;
    } else {
      if (!/^[0-9]+$/.test(phone)) {
        tempErrors["phone"] = "Phone number should contain only digits";
        isValid = false;
      } else if (!/^[0-9]{6,16}$/.test(phone)) {
        tempErrors["phone"] = "Phone number should be between 6 to 16 digits";
        isValid = false;
      }
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleVendorRegister = async () => {
    if (handleValidation()) {
      try {
        const body = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          country_code: "+91",
          phone_number: phone,
          password: password,
          type: "VENDOR",
        };
        const response = await _vendorRegister({ body });
        if (response.status) {
          sessionStorage.setItem("vendor_id", response?.data?.user_id);
          window.location.href = "/auth/organization";
        }
      } catch (error) {
        console.log(error);
        setApiResError(error?.response?.data?.message);
      }
    }
  };

  const countries = [
    { code: "+91", name: "India" },
    { code: "+234", name: "Nigeria" },
    { code: "+1", name: "USA" },
    { code: "+44", name: "UK" },
    { code: "+81", name: "Japan" },
    { code: "+61", name: "Australia" },
    { code: "+49", name: "Germany" },
    { code: "+86", name: "China" },
    { code: "+33", name: "France" },
    { code: "+55", name: "Brazil" },
  ];

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 mt-5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <div className="text-red-600">{apiResError}</div>
        <InputField
          variant="auth"
          extra="mb-3"
          label="First Name*"
          placeholder="Enter your first name"
          id="name"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        {errors.firstName && <span className="error">{errors.firstName}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Last Name*"
          placeholder="Enter your last name"
          id="name"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        {errors.lastName && <span className="error">{errors.lastName}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@mail.com"
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && <span className="error">{errors.email}</span>}
        <div>
          <label className="mb-1 block text-sm font-medium text-gray-700 dark:text-gray-300">
            Phone No*
          </label>
          <div className="flex items-center">
            <select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              className="mr-2 w-1/3 rounded-xl border border-solid border-gray-200 p-3"
            >
              {countries.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.code} ({country.name})
                </option>
              ))}
            </select>
            <input
              name="phoneNumber"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Enter your number"
              className="w-2/3 rounded-xl	border border-solid border-gray-200 p-3"
              required
            />
          </div>
        </div>
        {errors.phone && <span className="error">{errors.phone}</span>}

        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div> */}
        <button
          onClick={handleVendorRegister}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Next
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
