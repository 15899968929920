const baseUrl = "https://chatbotdev.plistbooking.app/api";
// const baseUrl = "http://localhost:8000/api";

const endpoints = {
  vendorRegister: () => `${baseUrl}/register`,
  createOrganization: () => `${baseUrl}/organizations/`,
  vendorLogin: () => `${baseUrl}/login`,
  vendorCategories: () => `${baseUrl}/vendor_categories`,
  botCreation: (userId) => `${baseUrl}/create_bot_initial?user_id=${userId}`,
  languageUpdate: () => `${baseUrl}/update_bot_support_langs`,
  activeLanguage: () => `${baseUrl}/get-active-languages`,
  toggleLanguage: () => `${baseUrl}/toggle-languages-status`,
  userProfile: () => `${baseUrl}/users/profile`,
  getOrganizationDetails: () => `${baseUrl}/user_organization`,
  getPaymentGateways: () => `${baseUrl}/payment-gateways`,
  updateBotKnowledgeDetails: () => `${baseUrl}/update_bot_knowledge_details`,
  botKnowledgeStatus: (task_id) => `${baseUrl}/task-status/${task_id}`,
  getLanguage: () => `${baseUrl}/languages`,
  updateBot: (bot_id) => `${baseUrl}/bot/${bot_id}`,
  getBot: (userId) => `${baseUrl}/user_bot?user_id=${userId}`,
  getUsersList: () => `${baseUrl}/users`,
  linkBotOwner: () => `${baseUrl}/link-bot-owners`,
  apiKnowledgeSource: (user_id) => `${baseUrl}/api_knowledge?user_id=${user_id}`,
  getApplicablePromptDetails: (bot_id, user_id) =>
    `${baseUrl}/get-applicable-prompts-and-flows?bot_id=${bot_id}&user_id=${user_id}`,
  updatePromptDetails: (bot_id, user_id) =>
    `${baseUrl}/update-applicable-prompts-and-flows?bot_id=${bot_id}&user_id=${user_id}`,
  getPromptDetails: (bot_id) =>
    `${baseUrl}/get-prompts-flows-and-steps?bot_id=${bot_id}`,
  updatePrompt: (prompt_id, step_id) =>
    `${baseUrl}/prompts/${prompt_id}/steps/${step_id}`,
  getEscalationRequests: (
    bot_id,
    page = 1,
    limit = 10,
    is_read,
    is_resolved,
    room_no
  ) =>
    `${baseUrl}/escalation-requests?bot_id=${bot_id}&page=${page}&limit=${limit}${
      is_read ? `&is_read=${is_read}` : ""
    }${is_resolved ? `&is_resolved=${is_resolved}` : ""}${
      room_no ? `&room_no=${room_no}` : ""
    }`,
  deactivateVendor: (user_id) => `${baseUrl}/users/toggle-user-state?user_id=${user_id}`,
  patchEscalationRequests: (id) => `${baseUrl}/escalation-requests/${id}`,
  getEscalationHistory: (id) =>
    `${baseUrl}/escalation_chat_by_escalation_request/${id}`,
  createSubaccount: () => `${baseUrl}/create-subaccount`,
  getApiKnowledgeSource: (bot_id, user_id) => `${baseUrl}/api_knowledge/${bot_id}?user_id=${user_id}`,
  updateApiKnowledgeSource: (bot_id, knowledge_id, user_id) => `${baseUrl}/api_knowledge/${bot_id}/${knowledge_id}?user_id=${user_id}`,
  getAllPrompts: () => `${baseUrl}/prompts`,
  updateAllPrompts: () => `${baseUrl}/prompts`,
  changePassword: (user_id) => `${baseUrl}/change-password?user_id=${user_id}`,
  vendorPassword: () => `${baseUrl}/users/password`,
  vendorUsername: (user_id) => `${baseUrl}/users/username?user_id=${user_id}`,
  forgotPassword: () => `${baseUrl}/users/forgot-password`,
  resetPassword: () => `${baseUrl}/auth/reset-password`,
};

export default endpoints;
