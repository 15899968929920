import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useState, useContext } from "react";
import { _vendorRegister } from "Services/api";
import { _createOrganization } from "Services/api";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "context/ProfileContext";

export default function Organization() {
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [apiResError, setApiResError] = useState();
  const navigate = useNavigate();
  const profileData = useContext(ProfileContext);

  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (!name) {
      tempErrors["name"] = "Name is required";
      isValid = false;
    }

    if (!website) {
      tempErrors["website"] = "Website URL is required";
      isValid = false;
    }

    if (!description) {
      tempErrors["description"] = "Description is required";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleOrganization = async () => {
    const vendor_id = sessionStorage.getItem("vendor_id")
    if (handleValidation()) {
      try {
        const body = {
          name: name,
          website: website,
          description: description,
          user_id: vendor_id
        };
        const response = await _createOrganization({ body });
        if (response.status) {
          sessionStorage.setItem("VendorToken", response?.data?.token)
          navigate(`/admin/${profileData.user_id}`);
        }
      } catch (error) {
        console.log(error);
        setApiResError(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white mt-5">
          Organization Creation
        </h4>
        <div className="text-red-600">{apiResError}</div>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Organization Name*"
          placeholder="Enter your organization name"
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && <span className="error">{errors.name}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Website*"
          placeholder="Enter your website url"
          id="name"
          type="text"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
        />
        {errors.website && <span className="error">{errors.website}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Description*"
          placeholder="Enter about your website"
          id="email"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {errors.description && <span className="error">{errors.description}</span>}
        {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div> */}
        <button
          onClick={handleOrganization}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Sign Up
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
