import React, { useEffect, useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import {
  Card,
  CardBody,
  CardFooter,
  IconButton,
  Button,
  Typography,
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { _deactiveVendor, _getUserList, _vendorPassword } from "Services/api";
import { toast } from "react-toastify";
import { FaUserEdit } from "react-icons/fa";
import { TbPasswordUser } from "react-icons/tb";
import { HiOutlineInformationCircle } from "react-icons/hi";

function VendorTable() {
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("VendorToken");

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    totalPages: 1,
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const [deactivatedRows, setDeactivatedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  // Password Reset State
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [resetPasswordData, setResetPasswordData] = useState({
    newPassword: "",
    confirmPassword: "",
    user_id: null,
  });
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordResetLoading, setIsPasswordResetLoading] = useState(false);

  // Function to fetch users
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await _getUserList({
        accessToken,
        page: pageInfo.page,
        limit: 10,
      });
      if (response.status) {
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);

        if (pageInfo.page === 1) {
          setAllUsers(response.data.allUsers || response.data.users);
        }

        setPageInfo({
          page: response.data.page,
          totalPages: response.data.total_pages,
          total: response.data.total,
        });
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  // Search Handler
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await _getUserList({
        accessToken,
        search: searchQuery,
      });

      if (response.status) {
        setFilteredUsers(response.data.users || []);
        setPageInfo({
          page: response.data.page || 1,
          totalPages: response.data.total_pages || 1,
          total: response.data.total || 0,
        });
      } else {
        console.error(response.message);
        setFilteredUsers([]);
      }
    } catch (error) {
      console.error("Error searching users:", error);
      setFilteredUsers([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery.trim() !== "") {
        handleSearch();
      } else {
        fetchUsers();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  // Pagination and Other Existing Methods
  useEffect(() => {
    fetchUsers();
  }, [pageInfo.page]);

  const handleStatusToggle = async (user_id) => {
    try {
      const response = await _deactiveVendor({ user_id, accessToken });
      if (response.status) {
        toast.success(response.message);

        setDeactivatedRows((prev) =>
          prev.includes(user_id)
            ? prev.filter((id) => id !== user_id)
            : [...prev, user_id]
        );

        fetchUsers();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error toggling vendor status:", error);
    }
  };

  const handleNextPage = () => {
    if (pageInfo.page < pageInfo.totalPages) {
      setPageInfo((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  const handlePrevPage = () => {
    if (pageInfo.page > 1) {
      setPageInfo((prev) => ({ ...prev, page: prev.page - 1 }));
    }
  };

  const handleCopyUrl = (user_id) => {
    const url = `https://chatbotdev.plistbooking.app/admin/${user_id}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
        toast.error("Failed to copy URL");
      });
  };

  // Password Reset Methods
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleOpenResetPasswordModal = (user_id) => {
    setResetPasswordData({
      newPassword: "",
      confirmPassword: "",
      user_id: user_id,
    });
    setPasswordError("");
    setIsResetPasswordModalOpen(true);
  };

  const handleCloseResetPasswordModal = () => {
    setResetPasswordData({
      newPassword: "",
      confirmPassword: "",
      user_id: null,
    });
    setPasswordError("");
    setIsResetPasswordModalOpen(false);
  };

  const handleResetPasswordChange = (e) => {
    const { name, value } = e.target;
    setResetPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPasswordError("");
  };

  const handleResetPasswordSubmit = async () => {
    const { newPassword, confirmPassword, user_id } = resetPasswordData;

    setPasswordError("");

    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match!");
      return;
    }

    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character"
      );
      return;
    }

    try {
      setIsPasswordResetLoading(true);

      const body = { new_password: newPassword, user_id: user_id };

      const response = await _vendorPassword({
        body,
        accessToken,
      });

      if (response.status) {
        toast.success(response.message || "Password reset successfully!");
        handleCloseResetPasswordModal();
      } else {
        toast.error(response.message || "Failed to reset password");
      }
    } catch (error) {
      console.error("Password reset error:", error);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setIsPasswordResetLoading(false);
    }
  };

  // Render Loading State
  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Typography variant="h6" color="blue-gray">
          Loading...
        </Typography>
      </div>
    );
  }

  const columns = ["Name", "Email", "Phone Number"];

  return (
    <>
      <Card className="h-full w-full overflow-scroll">
        <CardBody className="overflow-scroll px-0 pt-0">
          <div className="px-4 pt-4">
            <h1 class="text-2xl font-bold text-[#333]">Vendor List</h1>
          </div>
          <div className="p-4">
            <Input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Update state only
              className="w-full"
            />
          </div>
          <table className="w-full min-w-max table-auto text-left">
            <thead className="border-b">
              <tr>
                {columns.map((column) => (
                  <th key={column} className="p-4">
                    <Typography
                      variant="small"
                      className="font-bold leading-none"
                    >
                      {column}
                    </Typography>
                  </th>
                ))}
                <th className="p-4">
                  <Typography
                    variant="small"
                    className="font-bold leading-none"
                  >
                    Organization Type
                  </Typography>
                </th>
                <th className="p-4">
                  <Typography
                    variant="small"
                    className="font-bold leading-none"
                  >
                    Status
                  </Typography>
                </th>
                <th className="p-4">
                  <Typography
                    variant="small"
                    className="font-bold leading-none"
                  >
                    Actions
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, rowIndex) => {
                const isDeactivated =
                  deactivatedRows.includes(user.user_id) || !user.is_active;

                return (
                  <tr
                    key={user.user_id || rowIndex}
                    className={`p-4 hover:bg-green-100 ${
                      isDeactivated ? "bg-gray-200 text-gray-400" : ""
                    }`}
                  >
                    {/* User Details Columns */}
                    <td className="p-4">
                      <Typography variant="small" className="font-normal">
                        {user.full_name}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" className="font-normal">
                        <span
                          style={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "center",
                          }}
                        >
                          {user.email}
                          <span className="emailCopy">
                            <MdOutlineContentCopy
                              className="cursor-pointer"
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(user.email)
                                  .then(() => {
                                    toast.success("Email copied to clipboard!");
                                  })
                                  .catch((err) => {
                                    console.error("Failed to copy email:", err);
                                    toast.error("Failed to copy email.");
                                  });
                              }}
                            />
                          </span>
                        </span>
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" className="font-normal">
                        {user.phone_number}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" className="font-normal">
                        {user.org_type}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <Typography variant="small" className="font-normal">
                        {isDeactivated ? "Inactive" : "Active"}
                      </Typography>
                    </td>
                    <td className="p-4">
                      <div className="flex items-center gap-2">
                        <IconButton
                          variant="text"
                          size="sm"
                          className="align-center flex justify-center bg-gray-100"
                          title="Reset password for Vendor"
                          onClick={() =>
                            handleOpenResetPasswordModal(user.user_id)
                          }
                        >
                          <TbPasswordUser className="h-4 w-4 text-gray-900" />
                        </IconButton>

                        <IconButton
                          variant="text"
                          size="sm"
                          className="align-center flex justify-center bg-gray-100"
                          title="Copy Vendor URL"
                          onClick={() => handleCopyUrl(user.user_id)}
                        >
                          <MdOutlineContentCopy className="h-4 w-4 text-gray-900" />
                        </IconButton>
                        <IconButton
                          variant="text"
                          size="sm"
                          title="Edit Vendor"
                          className="align-center flex justify-center bg-gray-100"
                          onClick={() => navigate(`/admin/${user.user_id}`)}
                        >
                          <FaUserEdit className="h-4 w-4 text-gray-900" />
                        </IconButton>
                        <div
                          onClick={() => handleStatusToggle(user.user_id)}
                          className={`flex h-6 w-12 cursor-pointer items-center rounded-full p-1 transition-all ${
                            isDeactivated ? "bg-gray-300" : "bg-green-500"
                          }`}
                        >
                          <div
                            className={`h-4 w-4 transform rounded-full bg-white shadow-md transition-transform ${
                              isDeactivated ? "translate-x-0" : "translate-x-6"
                            }`}
                          ></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>

        <CardFooter className="border-blue-gray-50 flex items-center justify-between border-t p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {pageInfo.page} of {pageInfo.totalPages}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePrevPage}
              disabled={pageInfo.page === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={pageInfo.page === pageInfo.totalPages}
            >
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>

      {/* Reset Password Modal */}
      <Dialog
        open={isResetPasswordModalOpen}
        handler={handleCloseResetPasswordModal}
        className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-30"
        style={{ backdropFilter: "blur(5px)", maxWidth: "100%" }}
      >
        <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
          <DialogHeader className="text-center text-lg font-semibold">
            Reset Password
          </DialogHeader>
          <DialogBody className="flex flex-col gap-2">
          <div className="mb-4 rounded-lg bg-blue-50 p-4">
              <div className="flex items-center gap-2 mb-2">
                <HiOutlineInformationCircle className="h-5 w-5 text-blue-600" />
                <Typography variant="h6" color="blue" className="text-sm">
                  Password Requirements
                </Typography>
              </div>
              <ul className="list-none space-y-2 text-sm text-blue-800">
                <li className="flex items-start gap-2">
                  <span>•</span>
                  <span>Minimum 8 characters long</span>
                </li>
                <li className="flex items-start gap-2">
                  <span>•</span>
                  <span>Must include at least one uppercase letter (A-Z)</span>
                </li>
                <li className="flex items-start gap-2">
                  <span>•</span>
                  <span>Must include at least one lowercase letter (a-z)</span>
                </li>
                <li className="flex items-start gap-2">
                  <span>•</span>
                  <span>Must include at least one number (0-9)</span>
                </li>
                <li className="flex items-start gap-2">
                  <span>•</span>
                  <span>Must include at least one special character (@$!%*?&)</span>
                </li>
              </ul>
            </div>
            {passwordError && (
              <div className="mb-2 text-sm text-red-500">{passwordError}</div>
            )}

            <label
              htmlFor="newPassword"
              className="text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={resetPasswordData.newPassword}
              onChange={handleResetPasswordChange}
              required
              className="w-full rounded border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none"
            />

            {/* Confirm Password Field */}
            <label
              htmlFor="confirmPassword"
              className="text-sm font-medium text-gray-700"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={resetPasswordData.confirmPassword}
              onChange={handleResetPasswordChange}
              required
              className="w-full rounded border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none"
            />
          </DialogBody>
          <DialogFooter className="flex justify-end gap-2">
            <Button
              variant="text"
              color="red"
              onClick={handleCloseResetPasswordModal}
            >
              Cancel
            </Button>
            <button
              className="rounded-md bg-blue-500 py-2 px-4 text-white disabled:opacity-50"
              onClick={handleResetPasswordSubmit}
              disabled={isPasswordResetLoading}
            >
              {isPasswordResetLoading ? "Resetting..." : "Submit"}
            </button>
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
}

export default VendorTable;
