import React, { useEffect, useState } from 'react';
import Accordion from './Accordion';
import Table from './AccordionTable';
import { _getPromptDetails, _updatePromptSteps } from 'Services/api';  // Updated API

const AccordionComponent = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const [promptData, setPromptData] = useState([]);

  const botDetails = JSON.parse(sessionStorage.getItem("botData"));
  const botId = botDetails?.unique_id || botDetails?.bot_id;
  const accessToken = sessionStorage.getItem('VendorToken');

  useEffect(() => {
    const fetchPromptDetails = async () => {
      if (!botId) return;
      try {
        const response = await _getPromptDetails({ accessToken, botId });
        if (response.status) {
          setPromptData(response.data.prompts); // Set prompt data from API response
        }
      } catch (error) {
        console.error('Error fetching prompt details:', error);
      }
    };

    fetchPromptDetails();
  }, [accessToken, botId]);

  // Function to handle step content update
  const handleUpdateStep = async (updatedStep) => {
    const { prompt_id, step_id, step_content } = updatedStep;  
    const body = {
      question: step_content,  // Send the updated content as 'question'
    };
  
    try {
      const response = await _updatePromptSteps({
        prompt_id,    // Pass the prompt_id
        step_id,      // Pass step_order as step_id
        body,         // Send the updated content in the body
        accessToken,  // Pass the accessToken
      });
  
      if (response.status) {
        // Update the prompt data in the state with the new content
        setPromptData((prevData) =>
          prevData.map((prompt) =>
            ({
              ...prompt,
              steps: prompt.steps.map((step) =>
                step.step_order === updatedStep.step_order ? updatedStep : step
              )
            })
          )
        );
      }
    } catch (error) {
      console.error('Error updating step content:', error);
    }
  };
  
  
  
  

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  return (
    <div className="mt-3 p-4 bg-white rounded shadow-lg">
      {promptData.length > 0 ? (
        promptData.map((prompt, index) => (
          <Accordion
            key={prompt.id}
            title={prompt.prompt_name}
            isOpen={openAccordionIndex === index}
            onClick={() => handleAccordionClick(index)}
          >
<Table
  data={prompt.steps}  // Ensure prompt.steps contains step_id
  prompt_id={prompt.prompt_id} // Pass prompt_id as a prop
  onUpdateStep={handleUpdateStep}
/>
          </Accordion>
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default AccordionComponent;
